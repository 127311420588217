import React, { useState, useEffect } from 'react';
import { ViewNavbarAuthContainer } from '../../components/NavBar/ViewNavbarAuth';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts';
import { LoadingScreen } from '../../components/LoadingIndicator';
import { LocaleContext, useLocale } from '../../contexts/LocaleContext';
import Styles from './styles.module.css';
export const AuthLayout: React.FC<{
  children: JSX.Element;
}> = ({ children }): JSX.Element => {
  const { isAuthenticated, hasLoaded, fetchMe, userAttributes: { companies } } = useAuth();

  const location = useLocation();
  const isViewerPage = Boolean(location.pathname.split('/')[1] === 'viewer');
  const { themeMode, setThemeMode } = useLocale();
  useEffect(() => {
    void fetchMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hasLoaded || isAuthenticated === null) {
    return <LoadingScreen />;
  }

  return (
    <>
      {isAuthenticated && companies.length !== 0 ? (
        <LocaleContext.Consumer>
          {({ handleSetLocale }) => (
            <>
              {isViewerPage || (
                <ViewNavbarAuthContainer
                  handleSetLocale={handleSetLocale}
                  themeMode={themeMode}
                  setThemeMode={setThemeMode}
                />
              )}
              <div
                className={
                  themeMode ? Styles.appContainer : Styles.darkAppContainer
                }
              // style={{
              //   height: isViewerPage ? '100vh' : 'calc(100vh - 80px)',
              // }}
              >
                <div className='appElementContainer'>{children}</div>
              </div>
            </>
          )}
        </LocaleContext.Consumer>
      ) : (
        <Navigate to='/sign-in' replace />
      )}
    </>
  );
};
