// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_toast__dR-kN {\r\n    background: var(--layouts-background-color);\r\n    color: var(--label-color);\r\n}\r\n.styles_toast__dR-kN > div {\r\n    background: var(--layouts-background-color);\r\n    box-shadow: 0px 0px 7px -2px var(--layouts-shadow-color);\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/Toast/styles.module.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;IAC3C,yBAAyB;AAC7B;AACA;IACI,2CAA2C;IAC3C,wDAAwD;AAC5D","sourcesContent":[".toast {\r\n    background: var(--layouts-background-color);\r\n    color: var(--label-color);\r\n}\r\n.toast > div {\r\n    background: var(--layouts-background-color);\r\n    box-shadow: 0px 0px 7px -2px var(--layouts-shadow-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast": "styles_toast__dR-kN"
};
export default ___CSS_LOADER_EXPORT___;
