import { ProjectDto } from '../../services/company-projects/types';
import { ReactQueryKey } from '../constants/queries';
import { QueryClient } from 'react-query';
import { CompanyAttributes } from '../../contexts/types';

export const filterObjectsByKey = <T>(
  items: string[],
  objects: T[] | undefined,
  key: string,
): T[] => {
  if (objects) {
    return objects.filter((obj) =>
      items.every((item) => obj[key as keyof T] !== item),
    );
  }
  return [];
};

export const calculateSHA1 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const arrayBuffer = fileReader.result as ArrayBuffer;
      crypto.subtle
        .digest('SHA-1', arrayBuffer)
        .then((hashArrayBuffer) => {
          const hashBytes = new Uint8Array(hashArrayBuffer);
          const hashHex = Array.from(hashBytes)
            .map((byte) => byte.toString(16).padStart(2, '0'))
            .join('');
          resolve(hashHex);
        })
        .catch((error) => {
          reject(error);
        });
    };
    fileReader.onerror = () => {
      reject(fileReader.error);
    };
    fileReader.readAsArrayBuffer(file);
  });
};

// example: "2022-03-15"
export function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const year = date.getUTCFullYear() - 1;
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function byteToMB(size: number): string {
  return (size / 1048576).toFixed(2);
}

export const downloadFile = (url: string, items: any): void => {
  // fetch(url)
  //   .then((response) => response.blob())
  //   .then((blob) => {
  //     console.log('blobblobblobblobblobblobblobblobblob', blob);

  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     // link.download = 'desiredFilename1.rvt'; // Set the desired filename here
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   })
  //   .catch((error) => console.error('Error fetching the file:', error));

  const link: HTMLAnchorElement = document.createElement('a');
  link.href = url;
  link.target = '_self';
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const getProjectId = (
  queryClient: QueryClient,
  projectName: string,
): string => {
  return (
    queryClient
      .getQueryData<ProjectDto[]>(ReactQueryKey.CompanyProjects)
      ?.find((project) => project?.name === projectName)?.id ?? ''
  );
};

export const getCompanyName = (companies: CompanyAttributes[]): any => {
  const companyName = window.localStorage.getItem('cn');
  if (!companyName) {
    window.localStorage.setItem('cn', companies[0]?.companyName);
    return companies[0]?.companyName;
  } else if (companyName) {
    const company = companies.find(
      (company) => companyName === company.companyName,
    );
    if (company) {
      window.localStorage.setItem('cn', company.companyName);
      return company.companyName;
    } else {
      window.localStorage.setItem('cn', companies[0]?.companyName);
      return companies[0]?.companyName;
    }
  }
};

export const getPermission = (
  queryClient: QueryClient,
  projectName: string,
  action: string,
  setToastMessage: React.Dispatch<React.SetStateAction<string[]>>,
  setIsToastOpen: React.Dispatch<React.SetStateAction<boolean>>,
  role?: string,
): boolean => {
  if (role === '3B Manager' || role === 'Project Manager') return true;
  const companyProjects: ProjectDto[] | undefined =
    queryClient.getQueryData('Company Projects');
  const leng = window.localStorage.getItem('language');
  const project = companyProjects?.find((item) => item.name === projectName);
  if (project?.permissions) {
    if (project.permissions[action]) return true;
    else {
      if (leng === 'nl') {
        setToastMessage([
          'Je hebt geen toestemming!',
          'Neem contact op met de beheerder',
        ]);
      } else {
        setToastMessage([
          'You have not permission!',
          'Please contact the administrator',
        ]);
      }
      setIsToastOpen(true);
      return false;
    }
  }
  if (leng === 'nl') {
    setToastMessage([
      'Je hebt geen toestemming!',
      'Neem contact op met de beheerder',
    ]);
  } else {
    setToastMessage([
      'You have not permission!',
      'Please contact the administrator',
    ]);
  }
  setIsToastOpen(true);
  return false;
};
