import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import Styles from './styles.module.css';
import { NavLink, Link } from 'react-router-dom';
import logo3BView from '../../../assets/img/navBar/3BViewLogo.png';
import darkLogo3BView from '../../../assets/img/navBar/logo-3B-View-darkmode.png';
import { ViewNavbarAuthProps } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BurgerMenuContainer from './components/BurgerMenu/BurgerMenu.container';
import { faGear, faSun } from '@fortawesome/free-solid-svg-icons';
import { Language, Theme } from '../../../common/constants';
import darkModeIcon from '../../../pages/Viewer/viewer-sidebar/img/darkModeIcon.png';
import lightLogo from '../../../pages/Viewer/viewer-sidebar/img/lightLogo.png';
import LogOutIcon from '../../../assets/icons/log-out-icon.svg';
import LogOutLite from '../../../assets/img/navBar/logOutLite.png';
import UserIcon from '../../../assets/icons/user-icon.svg';
import userDark from '../../../assets/img/navBar/follower.png';
import ukFlag from '../../../assets/img/ukflag.png';
import nlFlag from '../../../assets/img/netherlands.png';
import { Tooltip } from '../../Tooltip/Tooltip';
import { useAuth } from '../../../contexts/AuthContext';
import messages from './messages';

import InfoModal from './components/userModal/UserPageModal';
import { getCompanyName } from '../../../common/utils/utils';

const ViewNavbarAuthView: React.FC<ViewNavbarAuthProps> = (
  props,
): JSX.Element => {
  const {
    userAttributes: { firstName, lastName, companies },
  } = useAuth();
  const companyName = getCompanyName(companies);
  const company = companies.find((i) => i.companyName === companyName);
  return (
    <div>
      {props.isOpenUserInfoModal && (
        <InfoModal
          phoneNumber={props.phoneNumber}
          isOpenUserInfoModal={props.isOpenUserInfoModal}
          setIsOpenUserInfoModal={props.setIsOpenUserInfoModal}
          setPhoneNumber={props.setPhoneNumber}
          userAttributes={props.userAttributes}
          setFile={props.setFile}
          handleUpdateProfileData={props.handleUpdateProfileData}
          preview={props.preview}
          setPreview={props.setPreview}
        />
      )}
      <nav className={Styles.saction}>
        <div className={Styles.container}>
          <div className={Styles.navbarResponsiv}>
            <div
              className={Styles.navBarListButton}
              onClick={props.handleOpenToggle}
            >
              <BurgerMenuContainer isOpen={props.isOpen} />
            </div>
            <div className={`${Styles.navbarLogo}`}>
              <Link to='/'>
                <img
                  src={props.themeMode ? logo3BView : darkLogo3BView}
                  alt='Logo'
                />
              </Link>
            </div>
          </div>
          <div
            className={cn(
              props.isOpen ? `${Styles.navbarList}` : '',
              `${Styles.navBarListDropDownBtn}`,
            )}
          >
            <ul className={Styles.navbarListItem}>
              <li>
                <NavLink
                  to='/company'
                  className={({ isActive }) =>
                    cn(`${isActive ? Styles.active : Styles.navLink}`)
                  }
                >
                  <FormattedMessage {...messages.company} />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/check'
                  className={({ isActive }) =>
                    cn(`${isActive ? Styles.active : Styles.navLink}`)
                  }
                >
                  <FormattedMessage {...messages.ilsCheck} />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/'
                  className={({ isActive }) =>
                    cn(`${isActive ? Styles.active : Styles.navLink}`)
                  }
                >
                  <FormattedMessage {...messages.formatChange} />
                </NavLink>
              </li>
              {company?.role === 'Collaborator' || (
                <li>
                  <NavLink
                    to='/dashboard/all-users'
                    className={({ isActive }) =>
                      cn(`${isActive ? Styles.active : Styles.navLink}`)
                    }
                  >
                    Dashboard
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
          <div className={Styles.navBarIconResponsiv}>
            <div className={Styles.drpoDownIconMenyu}>
              <div
                className={Styles.dropDownBtn}
                onClick={() => props.closeToggles()}
              >
                <button className='dark:bg-gray-500 dark:border-gray-500'>
                  <FontAwesomeIcon
                    icon={faGear}
                    className='h-7 w-7 text-black dark:text-white'
                  />
                </button>
              </div>
              <div
                className={cn(
                  props.toggle ? Styles.navbarIconeButton : '',
                  Styles.navBarDropDownBtn,
                )}
              >
                <div className='md:flex items-center space-x-3'>
                  <button
                    type='button'
                    className='p-2 lg:mr-4 mr-0 font-mono flex items-center justify-center dark:bg-gray-500 dark:border-gray-500 text-xs text-white bg-#f8f8f8 hover:bg-gray-900 rounded-full'
                    id='user-menu-button'
                    aria-expanded='false'
                    aria-haspopup='true'
                    onClick={() => {
                      props.setIsLocaleToggleOpen(!props.isLocaleToggleOpen);
                    }}
                  >
                    {props.locale === Language.Nl ? (
                      <img src={nlFlag} alt='dutch' />
                    ) : (
                      <img src={ukFlag} alt='english' />
                    )}
                  </button>
                </div>
                {props.isLocaleToggleOpen && (
                  <div
                    className={Styles.languageContainer}
                    role='menu'
                    aria-orientation='vertical'
                    aria-labelledby='user-menu-button'
                    tabIndex={-1}
                  >
                    <div>
                      <button
                        onClick={() => props.changeLocale(Language.En)}
                        className='flex items-center justify-center'
                      >
                        <img src={ukFlag} alt='english' />
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() => props.changeLocale(Language.Nl)}
                        className='flex items-center justify-center'
                      >
                        <img src={nlFlag} alt='dutch' />
                      </button>
                    </div>
                  </div>
                )}
                <div className={Styles.userIqone}>
                  <button
                    className='flex items-center lg:mr-4 '
                    onClick={() =>
                      props.setIsOpenUserInfoModal(!props.isOpenUserInfoModal)
                    }
                  >
                    <Tooltip
                      tooltipMessage={`${firstName} ${lastName}`}
                      themeMode={props.themeMode}
                    >
                      <span className='cursor-pointer text-sm font-normal'>
                        <img
                          src={props.themeMode ? UserIcon : userDark}
                          alt='user'
                        />
                      </span>
                    </Tooltip>
                  </button>
                </div>
                <div>
                  <button
                    className={Styles.back}
                    onClick={() => {
                      props.setThemeMode((prevMode: any) => !prevMode);
                    }}
                  >
                    <img
                      src={props.themeMode ? darkModeIcon : lightLogo}
                      alt=''
                    />
                  </button>
                </div>

                <div className='md:flex items-center space-x-3'>
                  <button
                    type='button'
                    id='user-auth-button'
                    aria-expanded='false'
                    aria-haspopup='true'
                    onClick={() =>
                      props.setIsLogOutToggleOpen(!props.isLogOutToggleOpen)
                    }
                    disabled={props.submitting}
                  >
                    <img
                      src={props.themeMode ? LogOutIcon : LogOutLite}
                      alt='logOut'
                    />
                  </button>
                </div>
                {props.isLogOutToggleOpen && (
                  <div
                    className={Styles.logOutToggle}
                    role='menu'
                    aria-orientation='vertical'
                    aria-labelledby='user-menu-button'
                    tabIndex={-1}
                  >
                    <div>
                      <span className='font-roboto'>
                        <FormattedMessage {...messages.logOutQuestion} />
                      </span>
                      <div className='flex justify-center gap-x-2 pt-1'>
                        <button
                          className='font-roboto'
                          type='button'
                          id='user-auth-confirm-button'
                          aria-expanded='false'
                          aria-haspopup='true'
                          onClick={props.handleLogOut}
                        >
                          <FormattedMessage {...messages.logOutQuestionYes} />
                        </button>
                        <button
                          className='font-roboto'
                          type='button'
                          id='user-auth-cancel-button'
                          aria-expanded='false'
                          aria-haspopup='true'
                          onClick={() => props.setIsLogOutToggleOpen(false)}
                        >
                          <FormattedMessage {...messages.logOutQuestionNo} />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default ViewNavbarAuthView;
