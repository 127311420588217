import React, {
  MouseEventHandler,
  useCallback,
  useState,
  useEffect,
} from 'react';
import ViewNavbarAuthView from './ViewNavbarAuth.view';
import { handleThemeSwitch, isDarkTheme } from '../utils';
import { useTheme } from '../hooks';
import { LANGUAGE_KEY, Theme } from '../../../common/constants';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useDashboardMutation } from '../../../pages/AdminPage/hooks';

export const ViewNavbarAuthContainer: React.FC<{
  handleSetLocale: (locale: string) => void;
  themeMode: any;
  setThemeMode: React.Dispatch<React.SetStateAction<boolean>>;
}> = React.memo(({ handleSetLocale, themeMode, setThemeMode }): JSX.Element => {
  const [toggle, setToggle] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isLocaleToggleOpen, setIsLocaleToggleOpen] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [isLogOutToggleOpen, setIsLogOutToggleOpen] = useState<boolean>(false);
  const [file, setFile] = useState<File | string>('');

  const { userAttributes, Toast } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState<string>(
    userAttributes.tel || '',
  );
  const [preview, setPreview] = React.useState<string | File | null>(
    userAttributes.profilePhotoUrl,
  );
  const [isOpenUserInfoModal, setIsOpenUserInfoModal] =
    useState<boolean>(false);
  const { EditProfileDataMutation } = useDashboardMutation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const locale: string = localStorage.getItem(LANGUAGE_KEY) ?? '';

  const handleOpenToggle: MouseEventHandler<HTMLDivElement> = (): void => {
    setIsOpen(!isOpen);
  };

  const handleUpdateProfileData = async () => {
    // if (!file && userAttributes.tel === phoneNumber) return;
    const formData: FormData = new FormData();
    formData.append('profilePhoto', file);
    formData.append('tel', phoneNumber);
    await EditProfileDataMutation.mutateAsync({
      formData,
      setToastConfig: Toast.setToastConfig,
    });
    Toast.setIsToastOpen(true);
    setIsOpenUserInfoModal(false);
    setFile('');
  };

  const handleLogOut = useCallback(async (): Promise<void> => {
    setSubmitting(true);
    try {
      await logout();
      setSubmitting(false);
      navigate('/');
    } catch (err: any) {
      setSubmitting(false);
    }
  }, [logout, navigate]);

  const changeLocale = (locale: string): void => {
    setToggle(!toggle);
    handleSetLocale(locale);
    setIsLocaleToggleOpen(!isLocaleToggleOpen);
  };

  const switchTheme = (): void => {
    // handleThemeSwitch(theme, setTheme);
    closeToggles();
  };

  const closeToggles = (): void => {
    setToggle(!toggle);
    setIsLocaleToggleOpen(false);
  };

  // useTheme(theme, setTheme);

  return (
    <ViewNavbarAuthView
      changeLocale={changeLocale}
      switchTheme={switchTheme}
      closeToggles={closeToggles}
      setIsLogOutToggleOpen={setIsLogOutToggleOpen}
      isLogOutToggleOpen={isLogOutToggleOpen}
      locale={locale}
      isLocaleToggleOpen={isLocaleToggleOpen}
      setIsLocaleToggleOpen={setIsLocaleToggleOpen}
      handleLogOut={handleLogOut}
      submitting={submitting}
      toggle={toggle}
      // theme={theme}
      // setTheme={setTheme}
      handleOpenToggle={handleOpenToggle}
      isOpen={isOpen}
      themeMode={themeMode}
      setThemeMode={setThemeMode}
      userAttributes={userAttributes}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      isOpenUserInfoModal={isOpenUserInfoModal}
      setIsOpenUserInfoModal={setIsOpenUserInfoModal}
      setFile={setFile}
      handleUpdateProfileData={handleUpdateProfileData}
      preview={preview}
      setPreview={setPreview}
    />
  );
});
