import { LoadingPage } from '../components/LoadingIndicator';
import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Retry } from './Retry';
import { GuestLayout } from './layouts/GuestLayout';
import { AuthLayout } from './layouts/AuthLayout';
import { useLocale } from '../contexts/LocaleContext';

const HomePage = React.lazy(() =>
  Retry(() => import('../pages/HomePage/Homepage.container')),
);
const AdminPage = React.lazy(() =>
  Retry(() => import('../pages/AdminPage/AdminPage.container')),
);
const SignInPage = React.lazy(() =>
  Retry(() => import('../pages/Authentication/SignInPage/SignIn.container')),
);
const FormatChange = React.lazy(() =>
  Retry(() => import('../pages/FormatChange/FormatChange.container')),
);
const Companies = React.lazy(() =>
  Retry(() => import('../pages/Companies/Companies.container')),
);
const Viewer = React.lazy(() =>
  Retry(() => import('../pages/Viewer/viewer.container')),
);
const ILSCheck = React.lazy(() =>
  Retry(() => import('../pages/ILSCheck/container')),
);
const PrintPDF = React.lazy(() =>
  Retry(() => import('../pages/PrintPDF/PrintPDF.container')),
);

export const AppRoutes: React.FC = (): JSX.Element => {
  return (
    <Routes>
      <Route path='/'>
        <Route
          index
          element={
            <Suspense fallback={<LoadingPage />}>
              <GuestLayout>
                <HomePage />
              </GuestLayout>
            </Suspense>
          }
        />
        <Route
          path='/sign-in'
          element={
            <Suspense fallback={<LoadingPage />}>
              <GuestLayout>
                <SignInPage />
              </GuestLayout>
            </Suspense>
          }
        />
        <Route
          path='/dashboard/*'
          element={
            <Suspense fallback={<LoadingPage />}>
              <AuthLayout>
                <AdminPage />
              </AuthLayout>
            </Suspense>
          }
        />
        <Route
          path='format-change'
          element={
            <Suspense fallback={<LoadingPage />}>
              <AuthLayout>
                <FormatChange />
              </AuthLayout>
            </Suspense>
          }
        />
        <Route
          path='/company'
          element={
            <Suspense fallback={<LoadingPage />}>
              <AuthLayout>
                <Companies />
              </AuthLayout>
            </Suspense>
          }
        />
        <Route
          path='/check/*'
          element={
            <Suspense fallback={<LoadingPage />}>
              <AuthLayout>
                <ILSCheck />
              </AuthLayout>
            </Suspense>
          }
        />
        <Route
          path='/viewer/*'
          element={
            <Suspense fallback={<LoadingPage />}>
              <AuthLayout>
                <Viewer />
              </AuthLayout>
            </Suspense>
          }
        />
        <Route
          path='/print-pdf/*'
          element={
            <Suspense fallback={<LoadingPage />}>
              <AuthLayout>
                <PrintPDF />
              </AuthLayout>
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};
