import React, { useState } from 'react';
import ViewNavbarGuestView from './ViewNavbarGuest.view';
import { handleThemeSwitch, isDarkTheme } from '../utils';
import { useLocation } from 'react-router-dom';
import { useTheme } from '../hooks';
import { LANGUAGE_KEY, Theme } from '../../../common/constants';

export const ViewNavbarGuestContainer: React.FC<{
  handleSetLocale: (locale: string) => void;
  themeMode: any;
  setThemeMode: React.Dispatch<React.SetStateAction<boolean>>;
}> = React.memo(({ handleSetLocale, themeMode, setThemeMode }): JSX.Element => {
  const [toggle, setToggle] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isSignInPage: boolean = useLocation().pathname === '/sign-in';
  const locale: string = localStorage.getItem(LANGUAGE_KEY) ?? '';

  const changeLocale = (locale: string): void => {
    setToggle(!toggle);
    handleSetLocale(locale);
    setIsOpen(!isOpen);
  };

  const switchTheme = (): void => {
    closeToggles();
  };

  const closeToggles = (): void => {
    setToggle(!toggle);
    setIsOpen(false);
  };

  return (
    <ViewNavbarGuestView
      locale={locale}
      isOpen={isOpen}
      switchTheme={switchTheme}
      closeToggles={closeToggles}
      changeLocale={changeLocale}
      setIsOpen={setIsOpen}
      toggle={toggle}
      setToggle={setToggle}
      // theme={theme}
      // setTheme={setTheme}
      isSignInPage={isSignInPage}
      themeMode={themeMode}
      setThemeMode={setThemeMode}
    />
  );
});
