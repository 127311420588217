import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

const BurgerMenuContainer: React.FC<{ isOpen: boolean }> = ({
  isOpen,
}): JSX.Element => {
  return (
    <>
      {!isOpen ? (
        <button className='dark:bg-black dark:border-gray-200 mr-2'>
          <FontAwesomeIcon
            icon={faXmark}
            className='h-6 w-6 text-black dark:text-white'
          />
        </button>
      ) : (
        <button className='dark:bg-black dark:border-gray-200 mr-2'>
          <FontAwesomeIcon
            icon={faBars}
            className='h-6 w-6 text-black dark:text-white'
          />
        </button>
      )}
    </>
  );
};

export default React.memo(BurgerMenuContainer);
