import { AuthApiEnum } from './consts';
import { fetchWrapper } from '../../common/fetchWrapper/fetchWrapper';

export async function loginPostRequest(
  value: any,
  REACT_APP_STAGE: string | undefined,
  setErrorModalShow: React.Dispatch<React.SetStateAction<string>>,
) {
  try {
    const env: string =
      REACT_APP_STAGE === 'development' ? '?development=true' : '';
    const response = await fetchWrapper(
      `${AuthApiEnum.Root}/${AuthApiEnum.SignIn}${env}`,
      {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(value),
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const data = await response.json().catch((error) => {
        return 'Something went wrong please try again later';
      });

      if (response.status >= 400) {
        return;
      }

      if (response.status === 500) {
        throw data.message;
      }
      throw data;
    }
  } catch (error: any) {
    setErrorModalShow(error);
    console.error('Error:', error);
  }
}
export async function getMeRequest(
  setErrorModalShow: React.Dispatch<React.SetStateAction<string>>,
) {
  try {
    const response = await fetchWrapper(
      `${AuthApiEnum.Root}/${AuthApiEnum.GetMe}`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const data = await response.json().catch((error) => {
        return 'Something went wrong please try again later';
      });
      if (response.status >= 400 || response.status === 500) {
        throw data.message;
      }
      throw data;
    }
  } catch (error: any) {
    // setErrorModalShow(error);
    console.error('Error:', error);
  }
}
export async function destroy(
  setErrorModalShow: React.Dispatch<React.SetStateAction<string>>,
) {
  try {
    const response: any = await fetchWrapper(`${AuthApiEnum.Root}`, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (response.ok) {
      const data = await response.text();
      return data;
    } else {
      const data = await response.json().catch((error: any) => {
        return 'Something went wrong please try again later';
      });
      if (response.status >= 400 || response.status === 500) {
        throw data.message;
      }
      throw data;
    }
  } catch (error: any) {
    // setErrorModalShow(error);
    console.error('Error', error);
  }
}
