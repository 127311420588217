import React, { useRef } from 'react';
import { useState } from 'react';
import Styles from './styles.module.css';
import user from '../../../../../assets//img/user.svg';
import pen from '../../../../../assets//img/pen.svg';
import cancelIcon from '../../../../../assets//img/del.png';
import { InfoModalProps } from '../../../types';
import { getCompanyName } from '../../../../../common/utils/utils';
import Avatar from '../../../../Avatar/Avatar';
import { useIntl } from 'react-intl';
import { useLocale } from '../../../../../contexts/LocaleContext';
import imageCompression from 'browser-image-compression';

const UserPageModal: React.FC<InfoModalProps> = (props): JSX.Element => {
  const [isPenClicked, setIPenClicked] = useState(false);
  const [base64Image, setBase64Image] = useState<any>('');
  const [scale, setScale] = useState<number>(1.2);
  const editor = useRef<any>();

  const handleImageUpload = async (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    const options = {
      scale: 1.5,
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };

      if (compressedFile) {
        reader.readAsDataURL(compressedFile);
        props.setFile(file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleScale = (event: any) => {
    if (event.deltaY > 0 && scale > 1) {
      setScale((prev) => prev - 0.1);
    } else if (event.deltaY < 0 && scale < 3.5) {
      setScale((prev) => prev + 0.1);
    }
    if (editor) {
      //@ts-ignore
      const canvas = editor.current.getImage().toDataURL();
      editor.current.getImage().toBlob((blob: any) => {
        let file = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
        props.setFile(file);

        console.log(file);
      }, 'image/jpeg');
      props.setPreview(canvas);
    }
  };

  const companyName = getCompanyName(props.userAttributes.companies);
  const company = props.userAttributes.companies.find(
    (company) => company.companyName === companyName,
  );
  const intl = useIntl();
  const { themeMode } = useLocale();
  return (
    <>
      <div
        className={Styles.modalOverlay}
        onClick={() => props.setIsOpenUserInfoModal(false)}
      >
        <div onClick={(e) => e.stopPropagation()} className={Styles.modalBox}>
          <h5>
            {' '}
            {intl.formatMessage({
              id: 'boilerplate.adminPage.PersonalData',
            })}
          </h5>
          <div className={Styles.container}>
            <div className={Styles.userImg}>
              <button className={Styles.userImgButton}>
                {/* <label> */}
                <img
                  src={props.preview || user}
                  className={Styles.userIcon}
                  alt=''
                />
                <div className={Styles.photoIcon} onWheel={handleScale}>
                  {!!base64Image || (
                    <div className={Styles.uploadInput}>
                      <label htmlFor='file'>
                        <input
                          id='file'
                          type='file'
                          title=''
                          onChange={handleImageUpload}
                        />
                        {props.preview ? 'Change photo' : 'Add photo'}
                      </label>
                    </div>
                  )}
                  <Avatar
                    editor={editor}
                    width={150}
                    height={150}
                    scale={scale}
                    preview={base64Image}
                    setPreview={props.setPreview}
                    setFile={props.setFile}
                  />
                  {props.preview && (
                    <button
                      className={Styles.deleteButton}
                      onClick={() => {
                        props.setPreview('');
                        setBase64Image('');
                        props.setFile('DELETE');
                      }}
                    >
                      Delete photo
                    </button>
                  )}
                </div>
                {/* </label> */}
              </button>
              <h6>
                {props.userAttributes.firstName +
                  ' ' +
                  props.userAttributes.lastName}
              </h6>
            </div>
            <div className={Styles.infoContainer}>
              <div className={Styles.userInfoContainer}>
                <div>
                  <label>
                    {intl.formatMessage({
                      id: 'boilerplate.adminPage.userInformation.contact.label.country',
                    })}
                  </label>
                  <h6> {props.userAttributes.country}</h6>
                </div>
                <div>
                  <label>
                    {intl.formatMessage({
                      id: 'boilerplate.adminPage.userInformation.contact.label.city',
                    })}
                  </label>
                  <h6>{props.userAttributes.city}</h6>
                </div>
                <div>
                  <label>Email</label>
                  <h6>{props.userAttributes.email}</h6>
                </div>
                <div>
                  <label>
                    {intl.formatMessage({
                      id: 'boilerplate.adminPage.userInformation.action.button.role',
                    })}
                  </label>
                  <h6>{company?.role}</h6>
                </div>
                <div>
                  <label>Tel.</label>
                  {isPenClicked ? (
                    <div className={Styles.tellInputDiv}>
                      <input
                        className={Styles.tellInput}
                        value={props.phoneNumber}
                        maxLength={16}
                        placeholder='Phone Number'
                        onChange={(e) => {
                          props.setPhoneNumber(e.target.value);
                        }}
                      />

                      <button
                        onClick={() => {
                          setIPenClicked(!isPenClicked);
                          props.setPhoneNumber(props.userAttributes.tel || '');
                        }}
                      >
                        <img src={cancelIcon} alt='' />
                      </button>
                    </div>
                  ) : (
                    <h6>
                      {props.phoneNumber}
                      <button
                        className={Styles.penButton}
                        onClick={() => {
                          setIPenClicked(!isPenClicked);
                        }}
                      >
                        <img src={pen} alt='' />
                      </button>
                    </h6>
                  )}
                </div>
              </div>

              <div className={Styles.buttonsDiv}>
                <button
                  className={Styles.cancelButton}
                  onClick={() => props.setIsOpenUserInfoModal(false)}
                >
                  {intl.formatMessage({
                    id: 'boilerplate.button.cancel',
                  })}
                </button>
                <button
                  className={Styles.saveButton}
                  onClick={props.handleUpdateProfileData}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPageModal;
