// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_darkAppContainer__UxrvI {\r\n  background: var(--body-background-color);\r\n  width: 100%;\r\n  height: calc(100vh - 80px);\r\n}\r\n.styles_appContainer__fL1nS {\r\n  width: 100%;\r\n  height: calc(100vh - 80px);\r\n  background: var(--body-background-color);\r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/routes/layouts/styles.module.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,WAAW;EACX,0BAA0B;AAC5B;AACA;EACE,WAAW;EACX,0BAA0B;EAC1B,wCAAwC;AAC1C","sourcesContent":[".darkAppContainer {\r\n  background: var(--body-background-color);\r\n  width: 100%;\r\n  height: calc(100vh - 80px);\r\n}\r\n.appContainer {\r\n  width: 100%;\r\n  height: calc(100vh - 80px);\r\n  background: var(--body-background-color);\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"darkAppContainer": "styles_darkAppContainer__UxrvI",
	"appContainer": "styles_appContainer__fL1nS"
};
export default ___CSS_LOADER_EXPORT___;
