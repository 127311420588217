import { RepeatUploadResEnum } from '../../services/files/types';

export const designDataUrl: string | undefined =
  process.env.REACT_APP_DESIGN_DATA_URL;
export const apiUrl: string | undefined = process.env.REACT_APP_API_URL;
export const baseUrl: string | undefined = process.env.REACT_APP_BASE_URL;
export const REACT_APP_STAGE: string | undefined = process.env.REACT_APP_STAGE;
export const TOKEN_KEY: string = 'tokens';
export const THEME_KEY: string = 'theme';
export const LANGUAGE_KEY: string = 'language';
// 15 minutes
// export const REFRESH_TOKEN_TIMER: number = 780000;
export const REFRESH_TOKEN_TIMER: number = 100000;

export const FAILURE_MESSAGE = 'Something went wrong, please try again later.';

export enum Theme {
  Dark = 'dark',
  Light = 'light',
}

export enum Language {
  En = 'en',
  Nl = 'nl',
}

export enum FileFormats {
  Rvt = 'rvt',
  Svf2 = 'svf2',
  Svf = 'svf',
  Ifc = 'ifc',
  Obj = 'obj',
  Pdf = 'pdf',
}

export enum State {
  CreationStarted = 'CREATION_STARTED',
  CreationPending = 'CREATION_PENDING',
  CreationCompleted = 'CREATION_COMPLETED',
  CreationFailed = 'CREATION_FAILED',
}

export const stateMap = {
  [State.CreationPending]: 'Pending',
  [State.CreationCompleted]: 'Completed',
  [State.CreationFailed]: 'Failed',
} as const;

export const conversionMap = {
  [RepeatUploadResEnum.ConversionPending]: 'Pending',
  [RepeatUploadResEnum.ConversionFailed]: 'Failed',
  [RepeatUploadResEnum.ConversionCompleted]: 'Completed',
} as const;

export enum Status {
  Success = 'success',
  Complete = 'complete',
  Error = 'error',
}
