import React from 'react';
import { ErrorModalView } from './ErrorModal.view';
import { ErrorModaProps } from './types';
export const ErrorModalContainer: React.FC<ErrorModaProps> = ({
  setErrorModalShow,
  isErrrModalShow,
}) => {
  return (
    <ErrorModalView
      setErrorModalShow={setErrorModalShow}
      isErrrModalShow={isErrrModalShow}
    />
  );
};
