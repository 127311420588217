import React, {
  createContext,
  useState,
  useCallback,
  ReactNode,
  useContext,
  useEffect,
} from 'react';
import messagesEn from '../common/translation/en.json';
import messagesNl from '../common/translation/nl.json';
import { Language, LANGUAGE_KEY } from '../common/constants';
import { IntlProvider } from 'react-intl';
import { ErrorModalContainer } from '../components/ErrorModal/ErrorModal.container';
interface LocalContextType {
  handleSetLocale: (locale: string) => void;
  getMode: () => any;
  isErrrModalShow: string;
  setErrorModalShow: React.Dispatch<React.SetStateAction<string>>;
  themeMode: boolean;
  setThemeMode: React.Dispatch<React.SetStateAction<boolean>>;
}
export const LocaleContext = createContext<LocalContextType>({
  handleSetLocale: (locale: string) => { },
  getMode: () => { },
  isErrrModalShow: '',
  setErrorModalShow: () => { },
  themeMode: true,
  setThemeMode: () => { },
});

const LocaleContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isErrrModalShow, setErrorModalShow] = useState<string>('');

  const [locale, setLocale] = useState<string>(
    localStorage.getItem(LANGUAGE_KEY) ?? Language.En,
  );
  const handleSetLocale = useCallback((locale: string): void => {
    setLocale(locale);
    localStorage.setItem(LANGUAGE_KEY, locale);
  }, []);
  const getMode = (): boolean => {
    return JSON.parse(localStorage.getItem('themeMode') || 'true');
  };

  const [themeMode, setThemeMode] = useState<boolean>(getMode);
  useEffect(() => {
    localStorage.setItem('themeMode', JSON.stringify(themeMode));
    if (!themeMode) document.body.classList.add("dark");
    else document.body.classList.remove("dark");
  }, [themeMode]);
  const messages = locale === Language.En ? messagesEn : messagesNl;

  const contextValue: LocalContextType = {
    handleSetLocale,
    getMode,
    isErrrModalShow,
    setErrorModalShow,
    setThemeMode,
    themeMode,
  };
  return (
    <IntlProvider locale={locale} messages={messages}>
      <LocaleContext.Provider value={contextValue}>

        {
          isErrrModalShow !== '' &&
          (
            <ErrorModalContainer
              isErrrModalShow={isErrrModalShow}
              setErrorModalShow={setErrorModalShow}
            />
          )
        }
        {children}
      </LocaleContext.Provider>
    </IntlProvider>
  );
};

export function useLocale(): LocalContextType {
  return useContext(LocaleContext);
}

export default LocaleContextProvider;
