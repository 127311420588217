import { apiUrl as BASE_URL } from "../constants";
import Cookie from "js-cookie";

interface FetchOptions extends RequestInit {
    headers?: HeadersInit;
}

interface RefreshTokenResponse {
    access_token: string;
    [key: string]: any;
}

const getAccessToken = (): string | null => {
    let token = localStorage.getItem('tokens');
    return token ? token.substring(1, token.length - 1) : null;
}

const setAccessToken = (token: string): void => {
    localStorage.setItem('tokens', JSON.stringify(token));
}

export const fetchWrapper = async <T>(url: string, options: FetchOptions = {}, type?: any): Promise<Response> => {
    let accessToken = getAccessToken();

    if (accessToken) {
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${accessToken}`,
        };
    }

    if (!type) {
        options.headers = {
            ...options.headers,
            'Content-Type': 'application/json',
        };
    }

    try {
        let response = await fetch(BASE_URL + url, options);

        if (response.status === 401) {
            const refreshSuccess = await refreshAccessToken();

            if (refreshSuccess) {
                accessToken = getAccessToken();
                if (accessToken) {
                    // Update the headers with the new access token
                    //@ts-ignore
                    options.headers['Authorization'] = `Bearer ${accessToken}`;
                    // Retry the original request with the new token
                    response = await fetch(BASE_URL + url, options);
                } else {
                    throw new Error('Failed to get new access token after refresh');
                }
            } else {
                throw new Error('Unauthorized');
            }
        } else if (response.status === 403) {
            Cookie.remove('Authentication', { domain: '3bview.nl' });
        }

        return response;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};

export const refreshAccessToken = async (): Promise<boolean> => {
    try {
        const response = await fetch(`${BASE_URL}auth/refresh-token`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data: RefreshTokenResponse = await response.json();
            setAccessToken(data.access_token);
            return true;
        } else {
            localStorage.removeItem("tokens");
            window.location.replace("/sign-in");
            return false;
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        return false;
    }
};