import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../../common/hooks';
import { ToastProps } from './types';
import { Status } from '../../common/constants';
import Styles from './styles.module.css';
import cn from 'classnames';

const Toast: React.FC<ToastProps> = ({
  variant,
  mainText,
  additionalText,
  autoHideDurationInSeconds = 5,
  onClose,
  isBackgroundBlur,
}): JSX.Element => {
  const [toastTimeOut, setToastTimeOut] =
    useState<ReturnType<typeof setTimeout>>();
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => onClose());

  useEffect(() => {
    const temporaryToastTimeOut = setTimeout(function () {
      onClose();
    }, autoHideDurationInSeconds * 1000);
    setToastTimeOut(temporaryToastTimeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toastClose = (): void => {
    onClose();
    if (toastTimeOut === undefined) {
      return;
    }
    clearTimeout(toastTimeOut);
  };

  return (
    <div
      className={cn('relative z-1500 h-full', Styles.toast)}
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      {isBackgroundBlur && (
        <div
          className='fixed inset-0 bg-opacity-75 transition-opacity'
          data-qa='Modal-Background'
        />
      )}
      <div
        className='w-96 h-auto p-4 rounded-lg shadow-lg fixed top-20 right-1/2 -mr-48'
        data-qa='Toast-Component'
        ref={ref}
      >

        <div className='flex flex-row justify-between align-center mb-1.5'>
          <div className='flex align-center'>
            <div className='mt-0.5 mr-5'>
              <FontAwesomeIcon
                icon={
                  variant === Status.Success ? faCircleCheck : faCircleXmark
                }
                className={`h-5 w-5 ${
                  variant === Status.Success ? 'text-green-400' : 'text-red-700'
                }`}
                aria-hidden={true}
              />
            </div>
            <div className='font-bold'>{mainText}</div>
          </div>
          <div className='cursor-pointer' onClick={() => toastClose()}>
            <FontAwesomeIcon icon={faX} className='text-gray-500' />
          </div>
        </div>
        <div className='flex ml-10 text-gray-400'>{additionalText}</div>
      </div>
    </div>
  );
};
export default React.memo(Toast);
