import React, { FC } from 'react';
import { TooltipProps } from './types';
import Styles from './styles.module.css';
export const Tooltip: FC<TooltipProps> = ({
  tooltipMessage,
  children,
  onClickHandler,
  themeMode,
}) => {
  return (
    <div
      className={`${Styles.tooltipContainer} group`}
      onClick={onClickHandler}
    >
      {children}
      <div className={`${Styles.tooltipContent} group-hover:flex`}>
        <div
          className={themeMode ? Styles.tooltipArrow : Styles.tooltipArrowDark}
        />
        <span
          className={
            themeMode ? Styles.tooltipMessage : Styles.tooltipMessageDark
          }
        >
          {tooltipMessage}
        </span>
      </div>
    </div>
  );
};
