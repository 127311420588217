import { defineMessages } from 'react-intl';

export default defineMessages({
  company: {
    id: 'boilerplate.Navbar.items.company',
    defaultMessage: 'Company 1',
  },
  ilsCheck: {
    id: 'boilerplate.Navbar.items.ilscheck',
    defaultMessage: 'ILS Check',
  },
  formatChange: {
    id: 'boilerplate.Navbar.items.formatChange',
    defaultMessage: 'Format Change 1',
  },
  aboutUs: {
    id: 'boilerplate.Navbar.items.aboutUs',
    defaultMessage: 'aboutUs 1',
  },
  blog: {
    id: 'boilerplate.Navbar.items.blog',
    defaultMessage: 'blog 1',
  },
  signIn: {
    id: 'boilerplate.Navbar.items.signIn',
    defaultMessage: 'signIn 1',
  },
  logOut: {
    id: 'boilerplate.Navbar.items.logOut',
    defaultMessage: 'Log out 1',
  },
  logOutQuestion: {
    id: 'boilerplate.Navbar.items.logOutQuestion',
    defaultMessage: 'Are you sure you want to log out?',
  },
  logOutQuestionYes: {
    id: 'boilerplate.Navbar.items.yes',
    defaultMessage: 'Yes!',
  },
  logOutQuestionNo: {
    id: 'boilerplate.Navbar.items.no',
    defaultMessage: 'Nee.',
  },
});
