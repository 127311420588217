// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_LoadingIcon__dPS89 {\r\n  border-top-color: #3498db;\r\n  animation: styles_LoadingIcon-spinner__-cy3S 1.5s linear infinite;\r\n}\r\n\r\n@keyframes styles_LoadingIcon-spinner__-cy3S {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n\r\n  100% {\r\n    transform: rotate(360deg);\r\n    -webkit-transform: rotate(360deg);\r\n  }\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/LoadingIndicator/styles.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EAEzB,iEAAmD;AACrD;;AAcA;EACE;IAEE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;IACzB,iCAAiC;EACnC;AACF","sourcesContent":[".LoadingIcon {\r\n  border-top-color: #3498db;\r\n  -webkit-animation: LoadingIcon-spinner 1.5s linear infinite;\r\n  animation: LoadingIcon-spinner 1.5s linear infinite;\r\n}\r\n\r\n@-webkit-keyframes LoadingIcon-spinner {\r\n  0% {\r\n    -webkit-transform: rotate(0deg);\r\n    transform: rotate(0deg);\r\n  }\r\n\r\n  100% {\r\n    -webkit-transform: rotate(360deg);\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n\r\n@keyframes LoadingIcon-spinner {\r\n  0% {\r\n    -webkit-transform: rotate(0deg);\r\n    transform: rotate(0deg);\r\n  }\r\n\r\n  100% {\r\n    transform: rotate(360deg);\r\n    -webkit-transform: rotate(360deg);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoadingIcon": "styles_LoadingIcon__dPS89",
	"LoadingIcon-spinner": "styles_LoadingIcon-spinner__-cy3S"
};
export default ___CSS_LOADER_EXPORT___;
