import React from 'react';
import cn from 'classnames';
import Styles from './Styles.module.css';
import { ErrorModaProps } from './types';
import { Dialog } from '@headlessui/react';
import { useLocale } from '../../contexts/LocaleContext';
import del from '../../assets/img/del.png';
import errorIcon from '../../assets/icons/error-icon.svg';

export const ErrorModalView: React.FC<ErrorModaProps> = ({
  setErrorModalShow,
  isErrrModalShow,
}) => {
  return (
    <Dialog
      open={true}
      onClose={() => setErrorModalShow('')}
      className={cn('fixed inset-0 overflow-y-auto', Styles.containerBox)}
    >
      <div className='min-h-screen px-4 text-center'>
        <Dialog.Overlay className='fixed inset-0 bg-black opacity-30' />

        <Dialog.Panel
          className={cn(
            'inline-block p-6 my-8 mt-60 verflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl', Styles.conteiner)}>
          <button
            className={Styles.xButton}
            onClick={() => {
              setErrorModalShow('');
              window.location.reload();
            }}
          >
            <img src={del} alt='' />
          </button>

          <Dialog.Title
            as='h3'
            className={cn(
              'text-lg font-medium font-roboto',
              Styles.modalTitle,
            )}
          >
            <img src={errorIcon} alt='' className={Styles.errorIcon} />{' '}
            {isErrrModalShow}
          </Dialog.Title>
          <div className='mt-4 flex justify-end'>
            <button
              type='button'
              className={cn(
                'inline-flex justify-center px-4 py-2 font-roboto ml-4 font-medium rounded-md ',
                Styles.buttonComponent,
              )}
              onClick={() => {
                setErrorModalShow('');
                window.location.replace('/');
              }}
            >
              OK
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
