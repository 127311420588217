import { io, Socket } from 'socket.io-client';
import { baseUrl } from '../../common/constants';

class Websocket {
  private _socket: Socket | undefined;

  public getInstance() {
    return this._socket;
  }

  public connect() {
    if (!this._socket) {
      this._socket = io(baseUrl as string, {
        reconnection: true,
        reconnectionAttempts: Number.MAX_VALUE,
        reconnectionDelay: 1000,
        autoConnect: true,
      });
      return this._socket;
    }
  }

  public joinRoom(id: string) {
    if (this._socket) {
      this._socket.emit('joinRoom', id);
    }
  }

  public disconnect(): void {
    if (this._socket) {
      this._socket.disconnect();
      this._socket = undefined;
    }
  }
}

// const webSocket = new Websocket();

export default Websocket;
