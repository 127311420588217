import { ErrorMessage } from 'formik';
import {
  UseQueryResult,
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query';
import {
  MemberDto,
  AllProjectsDto,
  AttachMamberMutation,
  EditRoleMutation,
  DetachMemberMutation,
  EditPermissionMutation,
  EditProfileDataMutation,
  AddUserMutation,
  RemoveUserMutation,
} from '../../services/dashboard/types';
import {
  getAllMembers,
  getAllProjects,
  getMember,
  AttachMemeber,
  EditRole,
  detachMember,
  EditPermissions,
  EditProfileData,
  addUser,
  RemoveUser,
} from '../../services/dashboard';
import { Status } from '../../common/constants';

export const useDashboardMutation = () => {
  const queryClient = useQueryClient();
  const AttachMemberMutation = useMutation(
    async ({
      companyName,
      projectName,
      userEmail,
      setToastConfig,
    }: AttachMamberMutation): Promise<Response | Error | undefined> =>
      await AttachMemeber(companyName, projectName, userEmail),
    {
      onSuccess: (response, { setToastConfig }) => {
        const leng = window.localStorage.getItem('language');
        if (response instanceof Response) {
          if (response.status === 201) {
            if (leng === 'nl') {
              setToastConfig({
                minText: 'Succesvol bijgevoegd!',
                maxText: '',
                status: Status.Success,
              });
            } else {
              setToastConfig({
                minText: 'Successfully attached!',
                maxText: '',
                status: Status.Success,
              });
            }
          } else if (response.status === 200) {
            if (leng === 'nl') {
              setToastConfig({
                minText: 'Al verkrijgbaar!',
                maxText: '',
                status: Status.Success,
              });
            } else {
              setToastConfig({
                minText: 'Already available!',
                maxText: '',
                status: Status.Success,
              });
            }
          }
        } else if (response instanceof Error) {
          if (leng === 'nl') {
            setToastConfig({
              minText: 'Something went wrong',
              maxText: 'Neem dan contact op met de beheerder!',
              status: Status.Error,
            });
          } else {
            setToastConfig({
              minText: 'Er is iets fout gegaan',
              maxText: 'Please contact the administrator!',
              status: Status.Error,
            });
          }
        }
      },
    },
  );
  const EditRoleMutation = useMutation(
    async ({
      companyName,
      role,
      userEmail,
      setToastConfig,
    }: EditRoleMutation): Promise<Response | Error | undefined> =>
      await EditRole(companyName, role, userEmail),
    {
      onSuccess: (response, { setToastConfig }) => {
        const leng = window.localStorage.getItem('language');
        if (response instanceof Response) {
          if (response.status === 201) {
            if (leng === 'nl') {
              setToastConfig({
                minText: 'Succesvol gekoppeld!',
                maxText: '',
                status: Status.Success,
              });
            } else {
              setToastConfig({
                minText: 'Successfully changed!',
                maxText: '',
                status: Status.Success,
              });
            }
            queryClient.invalidateQueries(['all members']);
          } else if (response.status === 200) {
            if (leng === 'nl') {
              setToastConfig({
                minText: 'Al bijgevoegd!',
                maxText: '',
                status: Status.Success,
              });
            } else {
              setToastConfig({
                minText: 'Already in role!',
                maxText: '',
                status: Status.Success,
              });
            }
          }
        } else if (response instanceof Error) {
          if (leng === 'nl') {
            setToastConfig({
              minText: 'Something went wrong',
              maxText: 'Neem dan contact op met de beheerder!',
              status: Status.Error,
            });
          } else {
            setToastConfig({
              minText: 'Er is iets fout gegaan',
              maxText: 'Please contact the administrator!',
              status: Status.Error,
            });
          }
        }
      },
    },
  );
  const DetachMemberMutation = useMutation(
    async ({
      companyName,
      projectName,
      userEmail,
    }: DetachMemberMutation): Promise<Response | Error | undefined> =>
      await detachMember(companyName, projectName, userEmail),
    {
      onSuccess: (response, { setToastConfig }) => {
        const leng = window.localStorage.getItem('language');
        if (response instanceof Response) {
          if (response.status === 204) {
            if (leng === 'nl') {
              setToastConfig({
                minText: 'Succesvol losmaken!',
                maxText: '',
                status: Status.Success,
              });
            } else {
              setToastConfig({
                minText: 'Detach successfully!',
                maxText: '',
                status: Status.Success,
              });
            }
            queryClient.invalidateQueries(['All Projects']);
          }
        } else if (response instanceof Error) {
          if (leng === 'nl') {
            setToastConfig({
              minText: 'Something went wrong',
              maxText: 'Neem dan contact op met de beheerder!',
              status: Status.Error,
            });
          } else {
            setToastConfig({
              minText: 'Er is iets fout gegaan',
              maxText: 'Please contact the administrator!',
              status: Status.Error,
            });
          }
        }
      },
    },
  );
  const EditPermissionMutation = useMutation(
    async ({
      companyName,
      projectName,
      permissions,
      userEmail,
    }: EditPermissionMutation): Promise<Response | Error | undefined> =>
      await EditPermissions(companyName, projectName, permissions, userEmail),
    {
      onSuccess: (response, { setToastConfig }) => {
        const leng = window.localStorage.getItem('language');
        if (response instanceof Response) {
          if (response.status === 200) {
            if (leng === 'nl') {
              setToastConfig({
                minText: 'Machtigingen zijn succesvol gewijzigd!',
                maxText: '',
                status: Status.Success,
              });
            } else {
              setToastConfig({
                minText: 'Permissions chenged successfully!',
                maxText: '',
                status: Status.Success,
              });
            }
            queryClient.invalidateQueries(['current user info']);
          }
        } else if (response instanceof Error) {
          if (leng === 'nl') {
            setToastConfig({
              minText: 'Something went wrong',
              maxText: 'Neem dan contact op met de beheerder!',
              status: Status.Error,
            });
          } else {
            setToastConfig({
              minText: 'Er is iets fout gegaan',
              maxText: 'Please contact the administrator!',
              status: Status.Error,
            });
          }
        }
      },
    },
  );
  const EditProfileDataMutation = useMutation(
    async ({
      formData,
    }: EditProfileDataMutation): Promise<Response | Error | undefined> =>
      await EditProfileData(formData),
    {
      onSuccess: (response, { setToastConfig }) => {
        const leng = window.localStorage.getItem('language');
        if (response instanceof Response) {
          if (response.status === 200) {
            if (leng === 'nl') {
              setToastConfig({
                minText: 'Profielgegevens zijn succesvol gewijzigd!',
                maxText: '',
                status: Status.Success,
              });
            } else {
              setToastConfig({
                minText: 'Profile Data was chenged successfully!',
                maxText: '',
                status: Status.Success,
              });
            }
          }
        } else if (response instanceof Error) {
          if (leng === 'nl') {
            setToastConfig({
              minText: 'Something went wrong',
              maxText: 'Neem dan contact op met de beheerder!',
              status: Status.Error,
            });
          } else {
            setToastConfig({
              minText: 'Er is iets fout gegaan',
              maxText: 'Please contact the administrator!',
              status: Status.Error,
            });
          }
        }
      },
    },
  );
  const RemoveUserMutation = useMutation(
    async ({
      companyName,
      userEmail,
    }: RemoveUserMutation): Promise<Response | Error | undefined> =>
      await RemoveUser(userEmail, companyName),
    {
      onSuccess: (response, { setToastConfig }) => {
        const leng = window.localStorage.getItem('language');
        if (response instanceof Response) {
          if (response.status === 204) {
            if (leng === 'nl') {
              setToastConfig({
                minText: 'Lid is succesvol verwijderd!',
                maxText: '',
                status: Status.Success,
              });
            } else {
              setToastConfig({
                minText: 'Member was removed successfully!',
                maxText: '',
                status: Status.Success,
              });
            }
          }
        } else if (response instanceof Error) {
          if (leng === 'nl') {
            setToastConfig({
              minText: 'Er is iets fout gegaan ',
              maxText: 'Neem dan contact op met de beheerder!',
              status: Status.Error,
            });
          } else {
            setToastConfig({
              minText: 'Something went wrong',
              maxText: 'Please contact the administrator!',
              status: Status.Error,
            });
          }
        }
      },
    },
  );
  const AddUserMutation = useMutation(
    async ({
      companyName,
      userData,
    }: AddUserMutation): Promise<Response | Error | undefined> =>
      await addUser(userData, companyName),
    {
      onSuccess: (response, { setToastConfig }) => {
        const leng = window.localStorage.getItem('language');
        if (response instanceof Response) {
          if (response.status === 200) {
            if (leng === 'nl') {
              setToastConfig({
                minText: 'Al toegevoegd!',
                maxText: '',
                status: Status.Success,
              });
            } else {
              setToastConfig({
                minText: 'Already added!',
                maxText: '',
                status: Status.Success,
              });
            }
          } else if (response.status === 201) {
            if (leng === 'nl') {
              setToastConfig({
                minText: 'Gebruiker is succesvol toegevoegd!',
                maxText: '',
                status: Status.Success,
              });
            } else {
              setToastConfig({
                minText: 'User was added successfully!',
                maxText: '',
                status: Status.Success,
              });
            }
          } else if (response.status === 400) {
            if (leng === 'nl') {
              setToastConfig({
                minText: 'Gebruiker niet gevonden!',
                maxText: '',
                status: Status.Error,
              });
            } else {
              setToastConfig({
                minText: 'User not found!',
                maxText: '',
                status: Status.Error,
              });
            }
          }
        } else if (response instanceof Error) {
          if (leng === 'nl') {
            setToastConfig({
              minText: 'Something went wrong',
              maxText: 'Neem dan contact op met de beheerder!',
              status: Status.Error,
            });
          } else {
            setToastConfig({
              minText: 'Er is iets fout gegaan',
              maxText: 'Please contact the administrator!',
              status: Status.Error,
            });
          }
        }
      },
    },
  );
  return {
    AttachMemberMutation,
    EditRoleMutation,
    DetachMemberMutation,
    EditPermissionMutation,
    EditProfileDataMutation,
    AddUserMutation,
    RemoveUserMutation,
  };
};

export const useMembers = (
  companyName: string,
): UseQueryResult<MemberDto[]> => {
  return useQuery(
    ['all members'],
    async () => await getAllMembers(companyName),
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const useOneMember = (
  companyName: string,
  email?: string,
): UseQueryResult<MemberDto> => {
  return useQuery(
    ['current user info'],
    async () => await getMember(companyName, email),
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const useAllProjects = (
  companyName: string,
): UseQueryResult<AllProjectsDto[]> => {
  return useQuery(
    'All Projects',
    async () => await getAllProjects(companyName),
    { refetchOnWindowFocus: false },
  );
};
