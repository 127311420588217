import React from 'react';

import {
  getAllMembersRequest,
  getMemberRequest,
  getAllProjectsRequest,
  AttachMemberRequest,
  EditeRoleRequest,
  DetachRequest,
  EditePermissionsRequest,
  EditProfileDataRequest,
  getViewerPermissionsRequest,
  addUserRequest,
  RemoveUserRequest,
} from '../../api/dashboard/index';
import { MemberDto, AllProjectsDto } from './types';
import { IPermissions, IAddUser } from '../../pages/AdminPage/types';

export const getAllMembers = async (
  companyName: string,
): Promise<MemberDto[]> => {
  const data = await getAllMembersRequest(companyName);
  return data;
};

export const getMember = async (
  companyName: string,
  email?: string,
): Promise<MemberDto[]> => {
  const data = await getMemberRequest(companyName, email);
  return data;
};
export const getAllProjects = async (
  companyName: string,
): Promise<AllProjectsDto[]> => {
  const data = await getAllProjectsRequest(companyName);
  return data;
};

export const AttachMemeber = async (
  companyName: string,
  projectName: string,
  userEmail?: string,
) => {
  try {
    return await AttachMemberRequest(companyName, projectName, userEmail);
  } catch (error) {
    console.error(error);
  }
};
export const detachMember = async (
  companyName: string,
  projectName: string,
  userEmail?: string,
) => {
  try {
    return await DetachRequest(companyName, projectName, userEmail);
  } catch (error) {
    console.error(error);
  }
};
export const EditRole = async (
  companyName: string,
  role: string,
  userEmail?: string,
) => {
  try {
    return await EditeRoleRequest(companyName, role, userEmail);
  } catch (error) {
    console.error(error);
  }
};
export const EditPermissions = async (
  companyName: string,
  projectName: string,
  permissions: IPermissions,
  userEmail?: string,
) => {
  try {
    return await EditePermissionsRequest(
      companyName,
      projectName,
      permissions,
      userEmail,
    );
  } catch (error) {
    console.error(error);
  }
};
export const EditProfileData = async (formData: FormData) => {
  try {
    return await EditProfileDataRequest(formData);
  } catch (error) {
    console.error(error);
  }
};
export const getViewerPermissions = async (urn: string) => {
  try {
    const data = await getViewerPermissionsRequest(urn);
    return data;
  } catch (error) {
    console.error(error);
  }
};
export const addUser = async (userData: IAddUser, companyName: string) => {
  try {
    const data = await addUserRequest(userData, companyName);
    return data;
  } catch (error) {
    console.error(error);
  }
};
export const RemoveUser = async (userEmail: string, companyName: string) => {
  try {
    const data = await RemoveUserRequest(userEmail, companyName);
    return data;
  } catch (error) {
    console.error(error);
  }
};
