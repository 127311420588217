export const NON_CACHED_QUERY_PROPS = {
  refetchOnMount: true,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

export const CACHED_QUERY_PROPS = {
  cacheTime: Infinity,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

export enum ReactQueryKey {
  CompanyProjects = 'Company Projects',
  Files = 'files',
  FilesThumbnails = 'files thumbnails',
}
