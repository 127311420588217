import { IPermissions, IAddUser } from '../../pages/AdminPage/types';

import { fetchWrapper } from '../../common/fetchWrapper/fetchWrapper';

// const access_token = localStorage.getItem('tokens');
// const validToken: any = generateValidAccessToken(access_token);

// function generateValidAccessToken(token: string | null) {
//   if (token === null) {
//     return new Error('Some Error');
//   }
//   return token.substring(1, token.length - 1);
// }

export const getAllMembersRequest = async (companyName: string) => {
  try {
    const response = await fetchWrapper(
      `companies/${companyName}/members/all/role`,
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getMemberRequest = async (companyName: string, email?: string) => {
  try {
    const response = await fetchWrapper(
      `companies/${companyName}/members/${email}`,
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};
export const getAllProjectsRequest = async (companyName: string) => {
  try {
    const response = await fetchWrapper(
      `companies/${companyName}/contributors`,
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const AttachMemberRequest = async (
  companyName: string,
  projectName: string,
  userEmail?: string,
) => {
  try {
    const response = await fetchWrapper(
      `companies/${companyName}/projects/${projectName}/contributors`,
      {
        method: 'POST',
        body: JSON.stringify({ userEmail }),
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const DetachRequest = async (
  companyName: string,
  projectName: string,
  userEmail?: string,
) => {
  try {
    const response = await fetchWrapper(
      `companies/${companyName}/projects/${projectName}/contributors`,
      {
        method: 'DELETE',
        body: JSON.stringify({ userEmail }),
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const EditeRoleRequest = async (
  companyName: string,
  role: string,
  userEmail?: string,
) => {
  try {
    const response = await fetchWrapper(
      `companies/${companyName}/members/role`,
      {
        method: 'PATCH',
        body: JSON.stringify({ userEmail, role }),
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const EditePermissionsRequest = async (
  companyName: string,
  projectName: string,
  permissions: IPermissions,
  userEmail?: string,
) => {
  try {
    const response = await fetchWrapper(
      `companies/${companyName}/projects/${projectName}/members/${userEmail}/permissions`,
      {
        method: 'PATCH',
        body: JSON.stringify(permissions),
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const EditProfileDataRequest = async (formData: FormData) => {
  try {
    const response = await fetchWrapper(
      `profile`,
      {
        method: 'PATCH',
        body: formData,
      },
      true,
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getViewerPermissionsRequest = async (urn: string) => {
  try {
    const response = await fetchWrapper(`check/permission`, {
      method: 'POST',
      body: JSON.stringify({ urn }),
    });
    const data = await response.json();
    return data;
  } catch (error) {}
};
export const addUserRequest = async (
  userData: IAddUser,
  companyName: string,
) => {
  try {
    const response = await fetchWrapper(`companies/${companyName}`, {
      method: 'POST',
      body: JSON.stringify(userData),
    });
    return response;
  } catch (error) {}
};
export const RemoveUserRequest = async (
  userEmail: string,
  companyName: string,
) => {
  try {
    const response = await fetchWrapper(`companies/${companyName}`, {
      method: 'DELETE',
      body: JSON.stringify({ userEmail }),
    });
    return response;
  } catch (error) {}
};
