import { QueryClient } from 'react-query';
import { FileFormats, State } from '../../common/constants';
import { ItemsToDownload } from '../../pages/Companies/components/Items/types';

export interface FileProto {
  format: FileFormats;
  sha1: string;
}

export interface Controller {
  controller?: AbortController;
  setErrorModalShow?: React.Dispatch<React.SetStateAction<string>>;
}

export interface CreateFileBody extends FileProto {
  convertFormat: FileFormats;
  file: File;
  setErrorModalShow: React.Dispatch<React.SetStateAction<string>>;
}

export interface SignFileBody extends FileProto {
  size: number;
  fileName?: string;
  setErrorModalShow: React.Dispatch<React.SetStateAction<string>>;
}

export interface CreateIfcDerivativeBody
  extends Omit<CreateFileBody, 'file' | 'queryClient'> {
  name: string;
  fileId: string;
  size: number;
  setErrorModalShow: React.Dispatch<React.SetStateAction<string>>;
}

export interface Params {
  companyName: string;
  projectName: string;
  checkDuplicate?: boolean;
  setErrorModalShow?: React.Dispatch<React.SetStateAction<string>>;
  queryClient?: QueryClient;
}

export enum RepeatUploadResEnum {
  ConversionCompleted = 'conversion_completed',
  ConversionFailed = 'conversion_failed',
  ConversionPending = 'conversion_pending',
}

export interface DerivativeSrcParams extends Params {
  urn: string;
  derivativeUrn: string;
  setErrorModalShow: React.Dispatch<React.SetStateAction<string>>;
}

export interface FileDownloadBody {
  items: ItemsToDownload[];
  setErrorModalShow: React.Dispatch<React.SetStateAction<string>>;
}

export interface FileUploadBody {
  file: File;
  url: string;
}

export interface SignFileDto {
  id: string;
  urlExpiration: Date;
  urls: string[];
  setErrorModalShow: React.Dispatch<React.SetStateAction<string>>;
}

export interface ConvertFileBody {
  id: string;
  name: string;
  format: FileFormats;
  setErrorModalShow: React.Dispatch<React.SetStateAction<string>>;
}

export type LinkedDerivative = {
  id: string;
  state: State;
  creatorId: string;
  createdAt: Date;
  updatedAt: Date;
  derivativeId: string;
};

export interface Derivative {
  id: string;
  isVisual?: boolean;
  format: FileFormats;
  urn: string;
  size: number;
  state: State;
  converterId: string;
  updatedAt: Date;
  createdAt: Date;
}

export interface FileDto {
  progress?: string;
  status?: string;
  createdAt: string;
  isVisual?: boolean;
  id: string;
  linkedDerivatives: LinkedDerivative[];
  linkerEmail: string;
  linkerId: string;
  name: string;
  state: State;
  updatedAt: string;
  uploadedFile: {
    createdAt: string;
    derivatives: Derivative[];
    format: FileFormats;
    id: string;
    sha1: string;
    size: number;
    src?: string;
    state: State;
    updatedAt: string;
    uploaderId: string;
    urn: string;
  };
  version: number;
}

export interface FileSourceDto {
  params: {
    'content-disposition': string;
    'content-type': string;
  };
  status: string;
  url: string;
  sha1: string;
  size: number;
}
