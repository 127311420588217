import React, { createContext, ReactNode, useEffect } from 'react';
import {
  TOKEN_KEY,
  REACT_APP_STAGE,
  Status,
} from '../common/constants';
import {
  getMeRequest,
  loginPostRequest,
  destroy,
} from '../api/auth/auth';
import { SignInFormValues } from '../pages/Authentication/SignInPage/types';
import { AuthContextType, UserAttributes, CompanyAttributes } from './types';

import { Socket } from 'socket.io-client';
import WebSocket from '../services/web-socket/Websocket';
import { useLocale } from './LocaleContext';
import { getCompanyName } from '../common/utils/utils';
import { ToastDto } from '../pages/AdminPage/components/SideBar/AllUsersSideBar/types';
import Toast from '../components/Toast/Toast';


const initialValue: UserAttributes = {
  userAfasId: '',
  email: '',
  firstName: '',
  middleName: '',
  tel: '',
  lastName: '',
  country: '',
  city: '',
  companies: [],
  userId: '',
  profilePhotoUrl: '',
};

export const AuthContext = createContext<AuthContextType>({
  hasLoaded: false,
  isAuthenticated: false,
  setIsAuthenticated: () => { },
  userAttributes: initialValue,
  login: async () => { },
  logout: async () => { },
  fetchMe: async () => { },
  socket: undefined,
  socketUser: undefined,
  connectWS: () => { },
  Toast: {
    setIsToastOpen: () => { },
    setToastConfig: () => { },
  },
});

const AuthContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { setErrorModalShow } = useLocale();

  const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);
  const [socket, setSocket] = React.useState<Socket | undefined>(undefined);
  const [socketUser, setSocketUser] = React.useState<Socket | undefined>(
    undefined,
  );
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(
    null,
  );
  const [isToastOpen, setIsToastOpen] = React.useState<boolean>(false);
  const [toastConfig, setToastConfig] = React.useState<ToastDto>({
    minText: '',
    maxText: '',
    status: Status.Complete,
  });
  const [userAttributes, setUserAttributes] =
    React.useState<UserAttributes>(initialValue);

  const connectWS = (companyId: string, userId: string): void => {
    try {
      const webSocketCompany = new WebSocket();
      const socketCompany = webSocketCompany.connect();
      webSocketCompany.joinRoom(companyId);

      const webSocketUser = new WebSocket();
      const socketUser = webSocketUser.connect();
      webSocketUser.joinRoom(userId);

      if (socketUser && userId) {
        setSocketUser(socketUser);
      } else {
        console.log('socketUser is undefined. Connection may have failed.');
        console.log(userId);
      }
      if (socketCompany && companyId) {
        setSocket(socketCompany);
      } else {
        console.log('socketCompany is undefined. Connection may have failed.');
      }
    } catch (error) {
      console.log('socket connection error', error);
    }
  };

  const loginHandler = async (values: SignInFormValues): Promise<void> => {
    try {
      setHasLoaded(true);
      const data = await loginPostRequest(
        values,
        REACT_APP_STAGE,
        setErrorModalShow,
      );
      const { access_token } = data;
      if (access_token) {
        window.localStorage.setItem(TOKEN_KEY, JSON.stringify(access_token));
        setIsAuthenticated(true);
      }
    } catch (e: any) {
      throw e.response.data;
    } finally {
      setHasLoaded(false);
    }
  };

  const fetchMe = async () => {
    setHasLoaded(true);
    try {
      const response = await getMeRequest(setErrorModalShow);
      if (response) {
        const companyName = getCompanyName(response.companies);
        let companyId = response.companies[0].companyId;
        response.companies.forEach((company: CompanyAttributes) => {
          if (company.companyName === companyName) {
            companyId = company.companyId;
          }
        });
        setIsAuthenticated(true);
        setUserAttributes(response);
        connectWS(companyId, response.userId);
      } else setIsAuthenticated(false);
    } catch (err) {
      console.error('Error:', err);
    } finally {
      setHasLoaded(false);
    }
  };
  const logoutHandler = async (): Promise<void> => {
    try {
      setHasLoaded(true);
      setIsAuthenticated(false);
      await destroy(setErrorModalShow);
      setUserAttributes(initialValue);
      window.localStorage.removeItem(TOKEN_KEY);
      window.localStorage.removeItem('cn');
    } catch (e: any) {
      console.log(e.message);
    } finally {
      setHasLoaded(false);
    }
  };

  const contextValue: AuthContextType = {
    hasLoaded,
    isAuthenticated,
    userAttributes,
    setIsAuthenticated,
    login: loginHandler,
    logout: logoutHandler,
    fetchMe,
    socket,
    socketUser,
    connectWS,
    Toast: {
      setIsToastOpen,
      setToastConfig,
    },
  };



  return (
    <AuthContext.Provider value={contextValue}>
      <>
        {isToastOpen && (
          <Toast
            mainText={toastConfig.minText}
            additionalText={toastConfig.maxText}
            onClose={() => setIsToastOpen(false)}
            variant={toastConfig.status}
          />
        )}
        {children}
      </>
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextType {
  return React.useContext(AuthContext);
}

export default AuthContextProvider;
