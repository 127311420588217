import React, { ReactNode, useCallback, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import AuthContextProvider from './contexts/AuthContext';
import LocaleContextProvider from './contexts/LocaleContext';

setLogger({
  log: console.log,
  warn: console.warn,
  error: console.warn,
});
const queryClient = new QueryClient();

export function Providers({ children }: { children: ReactNode }): JSX.Element {
  const dev = process.env.REACT_APP_STAGE === 'development';
  return (
    <BrowserRouter>
      <LocaleContextProvider>
        <AuthContextProvider>
          <QueryClientProvider client={queryClient}>
            {dev && <ReactQueryDevtools initialIsOpen={false} />}
            {children}
          </QueryClientProvider>
        </AuthContextProvider>
      </LocaleContextProvider>
    </BrowserRouter>
  );
}
