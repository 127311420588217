import React, { useEffect, useState } from 'react';
import { ViewNavbarGuestContainer } from '../../components/NavBar/ViewNavbarGuest';
import { Navigate } from 'react-router-dom';
import { LoadingScreen } from '../../components/LoadingIndicator';
import { useAuth } from '../../contexts/AuthContext';
import { LocaleContext, useLocale } from '../../contexts/LocaleContext';
import Styles from './styles.module.css';
export const GuestLayout: React.FC<{
  children: JSX.Element;
}> = ({ children }): JSX.Element => {
  const { isAuthenticated, hasLoaded, setIsAuthenticated } = useAuth();
  const { themeMode, setThemeMode } = useLocale();

  useEffect(() => {
    const token = window.localStorage.getItem("tokens");
    if (token) {
      setIsAuthenticated(true);
    }
  }, [])
  if (hasLoaded) {
    return <LoadingScreen />;
  }
  return (
    <>
      {isAuthenticated ? (
        <Navigate to='/company' replace />
      ) : (
        <LocaleContext.Consumer>
          {({ handleSetLocale }) => (
            <>
              <ViewNavbarGuestContainer
                handleSetLocale={handleSetLocale}
                themeMode={themeMode}
                setThemeMode={setThemeMode}
              />
              <div
                className={Styles.appContainer}
              >
                <div className='appElementContainer'>{children}</div>
              </div>
            </>
          )}
        </LocaleContext.Consumer>
      )}
    </>
  );
};
