import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import cn from 'classnames';
import Styles from './styles.module.css';
import { Link } from 'react-router-dom';
import logo3BView from '../../../assets/img/navBar/3BViewLogo.png';
import darkLogo3BView from '../../../assets/img/navBar/logo-3B-View-darkmode.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faSun } from '@fortawesome/free-solid-svg-icons';
import { ViewNavbarGuestProps } from '../types';
import { Language, Theme } from '../../../common/constants';
import darkModeIcon from '../../../pages/Viewer/viewer-sidebar/img/darkModeIcon.png';
import lightLogo from '../../../pages/Viewer/viewer-sidebar/img/lightLogo.png';
import ukFlag from '../../../assets/img/ukflag.png';
import nlFlag from '../../../assets/img/netherlands.png';
import messages from './messages';

const ViewNavbarGuestView: React.FC<ViewNavbarGuestProps> = (
  props,
): JSX.Element => {
  return (
    <>
      <nav className={Styles.saction}>
        <div className={Styles.container}>
          <div className={Styles.ponsive}>
            <div className={Styles.navbarLogo}>
              <Link to='/'>
                <img
                  src={props.themeMode ? logo3BView : darkLogo3BView}
                  alt='Logo'
                />
              </Link>
            </div>
          </div>
          <div className={Styles.navBarIconResponsive}>
            <div className={Styles.dropDownIconMenu}>
              <div
                className={Styles.dropDownBtn}
                onClick={() => props.closeToggles()}
              >
                <button className='mt-1 dark:bg-gray-500 dark:border-gray-200'>
                  <FontAwesomeIcon
                    icon={faGear}
                    className='h-7 w-7 text-black dark:text-white'
                  />
                </button>
              </div>
              <div
                className={cn(
                  props.toggle ? Styles.navbarIconeButton : '',
                  Styles.navBarDropDownBtn,
                )}
              >
                <div className='md:flex items-center space-x-3'>
                  <button
                    type='button'
                    className='p-2 lg:mr-4 font-roboto flex items-center justify-center dark:bg-gray-500 dark:border-gray-500 text-xs text-white bg-gray-800 hover:bg-gray-900 rounded-full'
                    id='user-menu-button'
                    aria-expanded='false'
                    aria-haspopup='true'
                    onClick={() => props.setIsOpen(!props.isOpen)}
                  >
                    {props.locale === Language.Nl ? (
                      <img src={nlFlag} alt='english' />
                    ) : (
                      <img src={ukFlag} alt='english' />
                    )}
                  </button>
                </div>
                {props.isOpen && (
                  <div
                    // className={`${
                    //   props.isSignInPage ? 'max-[900px]:right-[72px]' : ''
                    // }`}
                    className={Styles.languageContainer}
                    role='menu'
                    aria-orientation='vertical'
                    aria-labelledby='user-menu-button'
                    tabIndex={-1}
                  >
                    <div>
                      <button
                        onClick={() => props.changeLocale(Language.En)}
                        className='dark:bg-gray-500 dark:border-gray-500 flex items-center justify-center'
                      >
                        <img src={ukFlag} alt='english' />
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() => props.changeLocale(Language.Nl)}
                        className='dark:bg-gray-500 dark:border-gray-500 flex items-center justify-center'
                      >
                        <img src={nlFlag} alt='dutch' />
                      </button>
                    </div>
                  </div>
                )}
                <div>
                  <button
                    onClick={() => {
                      props.setThemeMode((prevMode: any) => !prevMode);
                    }}
                  >
                    <img
                      src={props.themeMode ? darkModeIcon : lightLogo}
                      alt='light'
                    />
                  </button>
                </div>
              </div>
            </div>
            {!props.isSignInPage && (
              <div className={Styles.signInButton}>
                <Link to='sign-in'>
                  <button className='w-32 h-12 flex justify-center items-center py-2 px-4 border border-transparent text-lg rounded-md text-white bg-fuchsia-800 font-roboto hover:bg-fuchsia-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                    <FormattedMessage {...messages.signIn} />
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default React.memo(ViewNavbarGuestView);
