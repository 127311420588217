import React, { useRef } from 'react';
import { IAvatar } from './types';
// import AvatarIMG from 'react-avatar-edit';
import AvatarEditor from 'react-avatar-editor';

const Avatar: React.FC<IAvatar> = (props) => {
  return (
    <div>
      {/* <AvatarIMG
        // imageWidth={400}
        width={props.width}
        height={props.height}
        onClose={onClose}
        onCrop={onCrop}
        src={src}
        label={props.preview ? 'Change Photo' : 'Add Photo'}
      /> */}
      <AvatarEditor
        ref={props.editor}
        width={props.width}
        height={props.height}
        image={props.preview}
        scale={props.scale}
        borderRadius={100}
        onMouseUp={() => {
          if (props.editor) {
            //@ts-ignore
            const canvas = props.editor.current.getImage().toDataURL();
            props.setPreview(canvas);
            props.editor.current.getImage().toBlob((blob: any) => {
              let file = new File([blob], 'fileName.jpg', {
                type: 'image/jpeg',
              });
              props.setFile(file);

              console.log(file);
            }, 'image/jpeg');
          }
        }}
        onImageReady={(onLoadSuccess) => {
          const canvas = props.editor.current.getImage().toDataURL();
          props.setPreview(canvas);
          props.editor.current.getImage().toBlob((blob: any) => {
            let file = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
            props.setFile(file);

            console.log(file);
          }, 'image/jpeg');
          console.log(onLoadSuccess, 'onImageChange');
        }}
        style={{ backgroundColor: '#96728a' }}
      />
    </div>
  );
};

export default Avatar;
